/* ParcFerm.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom styles for ParcFerm below */
@layer components {
  .checkbox-disabled {
    @apply cursor-not-allowed opacity-60 grayscale;
  }
  .highlight-row {
    @apply bg-red-100; /* Light red background for highlighted rows */
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above all other content */
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 1001; /* Ensure the modal is above the backdrop */
}

.button-dismiss {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

.button-dismiss:hover {
  background-color: #45a049; /* Darker green */
}


}
