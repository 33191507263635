/* table.css */

/* Style the table */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  thead {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }
  
  /* Style table headers and cells */
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Style table headers */
  th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #333;
  }
  
  /* Highlight rows on hover */
  tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Style links within the table */
  a {
    text-decoration: none;
    color: #007bff;
  }
  