@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    tbody tr {
        @apply border-b border-gray-200; /* Assuming you want a light gray border */
    }
    tbody tr:nth-child(odd) {
        @apply bg-white;
    }
    tbody tr:nth-child(even) {
        @apply bg-gray-50;
    }
    .highlight-row {
        @apply bg-red-100; /* Using Tailwind's color system */
    }
}

@layer components {
    .checkbox-disabled {
        @apply cursor-not-allowed opacity-60 grayscale;
    }
    .highlight-row {
        @apply bg-red-100; /* Light red background for highlighted rows */
    }
      .dropdown {
    @apply relative z-10; /* This will position the dropdown above other elements */
  }

  .table-header {
    @apply sticky top-0 z-0; /* This ensures the header is under the dropdown */
    background-color: white; /* Or any color that matches your design */
  }

  .dropdown-menu {
    @apply absolute w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none; /* Example dropdown menu styling */
  }
  .dropdown {
    @apply relative z-10; /* This will position the dropdown above other elements */
  }

  .table-header {
    @apply sticky top-0 z-0; /* This ensures the header is under the dropdown */
    background-color: white; /* Or any color that matches your design */
  }

  .dropdown-menu {
    @apply absolute w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none; /* Example dropdown menu styling */
  }
}
.pencil-link {
  cursor: pointer; /* Change cursor to pointer when hovered over */
  text-decoration: none; /* Remove default underline */
}

.pencil-link:hover {
  color: blue; /* Change text color when hovered over */
}
/* border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700 */